export class Cart {

  /** @type {CartApi} */
  cartApi;
  /** @type {Map<int, {}>} */
  items = new Map();
  /** @type {Map<int, {}>} */
  offersMap = new Map();
  /** @type {CartSummary} */
  summary = {};

  _listeners = {
    items_list_changed: [],
    offer_added: [],
    item_removed: [],
    cart_cleared: [],
    set_quantity: []
  };

  /**
   * @param {CartApi} cartApi
   */
  constructor(cartApi) {
    this.cartApi = cartApi;
  }

  fetchList() {
    this.cartApi.fetchList(response => {
      const diff = this._setItemsFromResponse(response);
      this.dispatchEvent('items_list_changed', diff);
    });
  }

  addOffer(offerId, qty) {
    return new Promise((resolve, reject) => {
      this.cartApi.addOffer(
        offerId, qty,
        (response) => {
          const diff = this._setItemsFromResponse(response);
          const payload = { offerId, response, diff };
          this.dispatchEvent('offer_added', payload);
          this.dispatchEvent('items_list_changed', diff);
          resolve(payload);
        },
        (errorText, errorThrown) => reject({errorText, errorThrown})
      );
    });
  }

  /**
   * @param {RestCartResponse} response
   * @private
   */
  _setItemsFromResponse(response) {
    const items = new Map();
    const offersMap = new Map();
    for (let itemId in response.result.items) {
      if (!response.result.items.hasOwnProperty(itemId)) continue;
      itemId = parseInt(itemId);
      if (itemId <= 0) continue;
      items.set(itemId, response.result.items[itemId]);
      const offerId = parseInt(response.result.items[itemId].OFFER_ID);
      offersMap.set(offerId, response.result.items[itemId]);
    }
    const itemsBackup = this.items;
    const OffersMapBackup = this.offersMap;
    const summaryBackup = this.summary;
    this.items = items;
    this.offersMap = offersMap;
    this.summary = response.result.summary;
    return {
      actual: {
        items: this.items,
        offersMap: this.offersMap,
        summary: this.summary
      },
      previous: {
        items: itemsBackup,
        offersMap: OffersMapBackup,
        summary: summaryBackup
      },
    };
  }

  removeItem(itemId) {
    return new Promise((resolve, reject) => {
      this.cartApi.removeItem(
        itemId,
        (response) => {
          const diff = this._setItemsFromResponse(response);
          const payload = { itemId, response, diff };
          this.dispatchEvent('item_removed', payload);
          this.dispatchEvent('items_list_changed', diff);
          resolve(payload);
        },
        (errorText, errorThrown) => reject({errorText, errorThrown})
      );
    });
  }

  setItemQuantity(itemId, qty) {
    // throw new Error('Method setItemQuantity is not implemented');
    return new Promise((resolve, reject) => {
      if (!this.isItemExist(itemId)) {
        reject(`Can't set quantity of non-exists item`);
      }
      this.cartApi.setQuantity(
        itemId, qty,
        (response) => {
          const diff = this._setItemsFromResponse(response);
          const payload = { response, diff };
          this.dispatchEvent('set_quantity', payload);
          this.dispatchEvent('items_list_changed', diff);
          resolve(payload);
        },
        (errorText, errorThrown) => reject({errorText, errorThrown})
      );
    });
  }

  setOfferQuantity(offerId, qty) {
    if (!this.offersMap.has(offerId)) {
      return Promise.reject(`Can't set quantity of non-exists offer`);
    }
    const itemId = parseInt(this.offersMap.get(offerId)['ID']);
    return this.setItemQuantity(itemId, qty);
  }

  clear() {
    return new Promise((resolve, reject) => {
      this.cartApi.clear(
        (response) => {
          const diff = this._setItemsFromResponse(response);
          const payload = { response, diff };
          this.dispatchEvent('cart_cleared', payload);
          this.dispatchEvent('items_list_changed', diff);
          resolve(payload);
        },
        (errorText, errorThrown) => reject({errorText, errorThrown})
      );
    });
  }

  isItemExist(itemId) {
    return this.items.has(itemId);
  }

  isOfferExist(offerId) {
    return this.offersMap.has(offerId);
  }

  getItemQuantity(itemId) {
    return this.items.has(itemId)
      ? parseFloat(this.items.get(itemId)['QUANTITY'])
      : 0.0;
  }
  getOfferQuantity(offerId) {
    return this.offersMap.has(offerId)
      ? parseFloat(this.offersMap.get(offerId)['QUANTITY'])
      : 0.0;
  }

  addEventListener(type, handler) {
    if (typeof type !== 'string' || type.length < 1) {
      throw new Error('event name should be a non-empty string');
    }
    if (typeof handler !== 'function') {
      throw new Error('event handler should be a function');
    }
    if (typeof this._listeners[type] !== 'object') {
      throw new Error('unknown event type');
    }
    this._listeners[type].push(handler);
  }

  dispatchEvent(type, payload) {
    if (typeof type !== 'string' || type.length < 1) {
      throw new Error('event name should be a non-empty string');
    }
    if (typeof this._listeners[type] !== 'object') {
      throw new Error('unknown event type');
    }
    this._listeners[type].forEach((handler) => {
      handler({type, payload});
    });
  }
}

export default Cart;
