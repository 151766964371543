const $ = global.jQuery3;

function noop() {}
function checkCallback(callback) {
  if( typeof(callback) != 'function' ) return noop;
  return callback;
}

/**
 * @typedef {{
 *   total: int,
 *   total_without_discount: int,
 *   total_discount: number,
 *   weight: number,
 *   count_avail: int,
 *   count_delayed: int,
 *   count_subscribed: int,
 *   count_not_avail: int,
 *   count_items: int,
 *   count_offers: int,
 *   formatted_total: string,
 *   formatted_total_without_discount: string,
 *   formatted_total_discount: string,
 *   formatted_weight: string,
 * }} CartSummary
 */

/**
 * @typedef {{
 *   action: string,
 *   status: string,
 *   message: string,
 *   result: {
 *     summary: CartSummary,
 *     affected: {
 *       added: int[],
 *       updated: int[]
 *       removed: int[]
 *       rejected: Object,
 *     },
 *     groups: {
 *       avail: int[],
 *       delayed: int[],
 *       subscribed: int[],
 *       not_avail: int[],
 *     },
 *     offersMap: Object,
 *     items: Object,
 *   }
 * }} RestCartResponse
 */

/**
 * @typedef {{
 *   ID: (int|string),
 *   OFFER_ID: (int|string),
 *   PRODUCT_ID: (int|string),
 *   NAME: string,
 *   QUANTITY: (number|string),
 *   PRICE: (number|string),
 *   CURRENCY: string,
 *   DISCOUNT_PRICE: (number|string),
 *   PRODUCT_PRICE_ID: (int|string),
 *   DELAY: string,
 *   CAN_BUY: string,
 *   SUBSCRIBE: string,
 *   DETAIL_PAGE_URL: string,
 *   PICTURE: (string|int|null|{
 *     SRC: string,
 *     WIDTH: ?(string|int),
 *     HEIGHT: ?(string|int),
 *     ALT: ?string
 *   })
 * }} RestCartItem
 */

/**
 *
 */
export class CartApi {
  constructor(restUrl) {
    this.restUrl = restUrl
  }

  addOffer(offerId, qty, cbSuccess, cbError) {
    cbSuccess = checkCallback(cbSuccess);
    cbError = checkCallback(cbError);
    offerId = parseInt(offerId);
    qty = parseFloat(qty);

    const requestData = {
      format: 'json',
      action: 'add',
      add_offers: {
        [offerId]: (isNaN(qty) || qty <= 0) ? 1.0 : qty
      }
    };

    $.ajax({
      url: this.restUrl,
      type: 'POST',
      format: 'json',
      data: requestData,
      error: function(jsXhr, errorText, errorThrown) {
        cbError(errorText, errorThrown);
      },
      /**
       * @param {RestCartResponse} response
       */
      success: function(response) {
        //onsole.log('dvt.sunways.cart.addOffer: response:', response);
        if( typeof(response.status) == 'undefined' || response.status === 'error' ) {
          // todo: trigger toastr message
          console.error('dvt.sunways.cart.addOffer: error: '+response.message);
          return;
        }
        if ( typeof response.result == 'undefined'
          || typeof response.result.offersMap == 'undefined'
          || typeof response.result.offersMap[offerId] == 'undefined'
        ) {
          console.error('dvt.sunways.cart.addOffer: wrong result data - offer not found');
          return;
        }
        const itemId = response.result.offersMap[offerId];

        /** @type {RestCartItem} */
        let item = (
          typeof response.result.affected.added != 'undefined'
          && Array.isArray(response.result.affected.added)
          && response.result.affected.added.indexOf(itemId) >= 0
          && typeof response.result.items[itemId] != 'undefined'
        ) ? response.result.items[itemId] : undefined;

        if( typeof item == 'undefined'
          || typeof item.ID == 'undefined'
          || typeof item.OFFER_ID == 'undefined'
          || typeof item.NAME == 'undefined'
          || typeof item.DETAIL_PAGE_URL == 'undefined'
          || typeof item.PRICE == 'undefined'
          || typeof item.QUANTITY == 'undefined'
        ) {
          console.error('dvt.sunways.cart.addOffer: error: wrong result data of added item', itemId, response);
          return;
        }

        item.ID = parseInt(item.ID);
        item.OFFER_ID = parseInt(item.OFFER_ID);
        item.PRICE = parseFloat(item.PRICE);
        item.QUANTITY = parseFloat(item.QUANTITY);
        if( item.OFFER_ID !== offerId ) {
          debugger;
          console.error('dvt.sunways.cart.addOffer: error: requested and added offer IDs is not equal');
          return;
        }
        if (typeof item.PICTURE == 'undefined' || typeof item.PICTURE.SRC == 'undefined') {
          console.warn('dvt.sunways.cart.addOffer: warning: added item not includes picture info');
        }
        cbSuccess(response);
      }
    });
  }

  setQuantity(itemId, quantity, cbSuccess, cbError) {
    cbSuccess = checkCallback(cbSuccess);
    cbError = checkCallback(cbError);

    const requestData = {
      format: 'json',
      action: 'update',
      qty: {}
    };

    requestData.qty[itemId] = quantity;
    $.ajax({
      url: this.restUrl,
      type: 'POST',
      format: 'json',
      data: requestData,
      error: function(jsXhr, errorText, errorThrown) {
        cbError(errorText, errorThrown);
      },
      success: function(response) {
        if( typeof(response.status) == 'undefined' || response.status === 'error' ) {
          console.error('dvt.sunways.cart.setQuantity: error: '+response.message);
          return;
        }
        if ( typeof response.result == 'undefined'
          || typeof response.result.affected.updated == 'undefined'
          || !Array.isArray(response.result.affected.updated)
          || response.result.affected.updated.length < 1
        ) {
          console.error('dvt.sunways.cart.setQuantity: wrong result data');
          return;
        }
        if ( response.result.affected.updated.indexOf(itemId) < 0 ) {
          console.error(`dvt.sunways.cart.setQuantity: response updated items is not contain requested itemId="${itemId}"`)
        }
        cbSuccess(response);
      }
    });
  }

  removeItem(itemId, cbSuccess, cbError) {
    cbSuccess = checkCallback(cbSuccess);
    cbError = checkCallback(cbError);

    $.ajax({
      url: this.restUrl,
      type: 'POST',
      format: 'json',
      data: {
        action: 'remove',
        remove_items: [itemId]
      },
      error: function(jsXhr, errorText, errorThrown) {
        cbError(errorText, errorThrown);
      },
      success: function(response) {
        //onsole.log('rest: remove Item: data', data);
        if( typeof(response.status) == 'undefined' || response.status === 'error' ) {
          // todo: trigger toastr message
          console.error('dvt.sunways.cart.removeItem: error: '+response.message);
          return;
        }
        if( typeof(response.result) == 'undefined'
          || typeof(response.result.affected) == 'undefined'
          || !Array.isArray(response.result.affected.removed)
          || response.result.affected.removed.length < 1
          || response.result.affected.removed.indexOf(itemId) < 0
        ) {
          if (typeof response.result.affected.rejected === 'object'
            && response.result.affected.rejected !== null
            && typeof response.result.affected.rejected[itemId] === 'string'
          ) {
            console.error(
              'dvt.sunways.cart.removeItem: rejected:',
              response.result.affected.rejected[itemId]
            );
          } else {
            console.error('dvt.sunways.cart.removeItem: wrong response');
          }
          return;
        }
        cbSuccess(response);
      }
    })
  }

  fetchList(cbSuccess, cbError) {
    cbSuccess = checkCallback(cbSuccess);
    cbError = checkCallback(cbError);
    $.ajax({
      url: this.restUrl,
      type: 'POST',
      format: 'json',
      data: {
        action: 'list'
      },
      error: (jsXhr, errorText, errorThrown) => {
        cbError(errorText, errorThrown);
      },
      success: response => {

        cbSuccess(response);
      }
    });
  }

  clear(cbSuccess, cbError) {
    cbSuccess = checkCallback(cbSuccess);
    cbError = checkCallback(cbError);
    $.ajax({
      url: this.restUrl,
      type: 'POST',
      format: 'json',
      data: {
        action: 'clear'
      },
      error: function (jsXhr, errorText, errorThrown) {
        cbError(errorText, errorThrown);
      },
      success: function (response) {
        cbSuccess(response);
      }
    });
  }
}
export default CartApi;
