/**
 * @param $
 * @param {Cart} cart
 */
export default function($, cart) {
  const $document = $(document);
  const btnBuySelector = '.btn-buy' +
    '[data-offer-id]' +
    '[data-offer-avail=true]' +
    '[data-btn-buy-already-in-class]';

  $document.on('click', btnBuySelector, function(_event) {
    const $btn = $(this);
    const offerId = $btn.data('offer-id');
    const alreadyIn = checkBtnAlreadyIn($btn);

    if(alreadyIn) {
      getModal().modal('show');
    } else {
      const $quantityInput = $(`input[data-quantity-of-offer-id=${offerId}]`);
      const quantity = $quantityInput.length > 0 ? parseFloat($quantityInput.val()) : 1.0;
      cart.addOffer(offerId, quantity)
        .then(function() {
          checkBtnAlreadyIn($btn);
        })
        .catch(function(error) {
          console.error('add offer error', error);
        });
    }
  });

  $(function() {
    const $buttons = $(btnBuySelector);
    const $quantityInputs = $(`input[data-quantity-of-offer-id]`);
    $buttons.each(function() {
      checkBtnAlreadyIn($(this));
    });
    cart.addEventListener(
      'items_list_changed',
      _event => {
        $buttons.each(function() {
          checkBtnAlreadyIn($(this));
        });
        $quantityInputs.each(function() {
          syncQuantityInput($(this));
        });
      }
    );
    const $modal = getModal();
    $('body').append($modal);

    const quantityInputDebounceDelay = 500;
    let quantityInputDebounceTimeoutId = 0;
    $quantityInputs.on('change', function() {
      const input = this;
      clearTimeout(quantityInputDebounceTimeoutId);
      const offerId = parseInt(input.getAttribute('data-quantity-of-offer-id'));
      if (offerId < 1 || !cart.isOfferExist(offerId)) return;
      quantityInputDebounceTimeoutId = setTimeout(() => {
        cart.setOfferQuantity(offerId, input.value);
      }, quantityInputDebounceDelay);
    })
  });

  function checkBtnAlreadyIn($btn) {
    const offerId = $btn.data('offer-id');
    let caption = $.trim($btn.data('btn-buy-caption'));
    let captionAlreadyIn = $.trim($btn.data('btn-buy-already-in-caption'));
    let classAlreadyIn = $.trim($btn.data('btn-buy-already-in-class'));
    if (caption.length < 1)  caption = 'В корзину';
    if (captionAlreadyIn.length < 1) captionAlreadyIn = 'Уже в корзине';
    if (classAlreadyIn.length < 1) classAlreadyIn = 'btn-buy_already-in';

    if( cart.isOfferExist(offerId) ) {
      if (!$btn.hasClass(classAlreadyIn)) {
        $btn.addClass(classAlreadyIn);
      }
      $btn.text(captionAlreadyIn);
      // $btn.attr('disabled', 'disabled');
      return true;
    } else {
      if ($btn.hasClass(classAlreadyIn)) {
        $btn.removeClass(classAlreadyIn);
      }
      $btn.text(caption);
      // $btn.removeAttr('disabled');
      return false;
    }
  }

  function syncQuantityInput($qtyInput) {
    const offerId = parseInt($qtyInput.attr('data-quantity-of-offer-id'));
    const qty = cart.getOfferQuantity(offerId);
    if (qty > 0) {
      $qtyInput.val(qty);
    }
  }

  let _$alreadyInModal;
  function getModal() {
    if (_$alreadyInModal) return _$alreadyInModal;
    return _$alreadyInModal = $(`
      <div class="modal sw-modal fade"
           id="alreadyInCartModal"
           data-backdrop="static"
           data-keyboard="false"
           tabIndex="-1"
           aria-labelledby="alreadyInCartModalTitle"
           aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered sw-modal__dialog">
          <div class="modal-content sw-modal__content">
            <button type="button" class="sw-modal__close sif sif-close" data-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body sw-modal__body">
              <div class="sw-modal__title">
                Товар уже добавлен.
              </div>
              <div class="sw-modal__form">
                Вы хотите перейти в корзину или продолжить покупки?
              </div>
            </div><!-- /.modal-body.sw-modal__body -->
            <div class="modal-footer sw-modal__footer">
              <button type="button"
                      class="btn btn-o btn-o_border"
                      data-dismiss="modal">Продолжить покупки</button>
              <a class="btn btn-o btn-buy-modal__goto-cart"
                 href="/personal/checkout/">Перейти в корзину</a>
            </div>
          </div><!-- /.sw-modal__content -->
        </div><!-- /.modal-dialog -->
      </div>
    `);
  }
}
