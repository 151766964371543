import CartApi from './lib/cart-api';
import Cart from "./lib/cart";
import behaviourBuyButton from './behaviour/btn-buy';

const cartApi = new CartApi('/ajax/cart.php');
const cart = new Cart(cartApi);

global.sunways_shop.cart = cart;
global.sunways_shop.cartApi = cartApi;

behaviourBuyButton(global.jQuery3, cart);

cart.fetchList();
